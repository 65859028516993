<template>
  <div>
    <Container>
    <b-tabs v-model="activeTab" expanded>
    
      <b-tab-item label="Car" icon="car">
        <ZoneToggle/>
        <CarStations/>
      </b-tab-item>

      <b-tab-item label="Bus" icon="bus">
          <BusDisplay icon="fas fa-shipping-fast" :filter="(state) => ['ARRIVED'].includes(state)" actionState="CLEARED" :hasAction="true"/>
      </b-tab-item>
    </b-tabs>
    </Container>
  </div>
</template>


<script>
import CarStations from "../components/CarStations";
import BusDisplay from "../components/BusDisplay";
import Container from "../components/Container";
import ZoneToggle from "../components/ZoneToggle.vue";

// import BusItem from "../components/BusItem";

import { mapGetters } from "vuex";

export default {
  components: { CarStations, BusDisplay, Container, ZoneToggle },
  computed: {
    ...mapGetters({
      students: "getStudents",
      teachers: "getTeachers",
      dismissalProviders: "getDismissalProviders",
    }),
    locations() {
      return this.dismissalProviders
        ? this.dismissalProviders.filter(({ type }) => type !== "Car")
        : [];
    },
  },
  data() {
    return {
      activeTab: this.$store.state.transitModeTab,
    };
  },
  watch: {
    activeTab(val) {
      this.$store.commit('setTabState', val)
    }
  }
};
</script>
