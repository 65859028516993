<template>
  <b-navbar :fixed-top="true" :type="'is-primary'" :mobile-burger="!!user.data">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ name: 'Home' }" v-if="schoolMeta">
        <b class="title is-5 has-text-white">{{ schoolMeta.name }}</b>
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        :to="user.loggedIn ? { name: 'Schools' } : { name: 'Welcome' }"
        v-else
      >
        <b class="title is-5 has-text-white">LB Dismiss</b>
      </b-navbar-item>
    </template>

    <template #start v-if="user.data && $route.params.school">
      <navbar-item :to="{ name: 'Classroom' }" />

      <b-navbar-dropdown label="Mobile">
        <navbar-item :to="{ name: 'Student/Bus Lookup' }" />
        <navbar-item :to="{ name: 'Caller' }" />
        <navbar-item :to="{ name: 'Staging' }" />
        <navbar-item :to="{ name: 'Cleared' }" />
      </b-navbar-dropdown>

      <navbar-item
        :to="{ name: 'Admin' }"
        v-if="user.data && user.data.role == 'admin'"
      />
      <!-- <b-navbar-item href="#"> Documentation </b-navbar-item> -->
    </template>

    <template #end v-if="user.data">
      <div class="navbar-item has-dropdown is-hoverable" id="usernav">
        <a class="navbar-link" id="user-info">
          <span id="username">{{ user.data.displayName }}</span>
          <div class="navbar-item">
            <figure class="image is-24x24">
              <img id="profile" class="is-rounded" :src="user.data.photoURL" />
            </figure>
          </div>
        </a>

        <div class="navbar-dropdown">
          <div class="navbar-item" id="email">{{ user.data.email }}</div>
          <hr class="navbar-divider" />

          <router-link class="navbar-item" :to="{ name: 'Schools' }">
            My Schools
          </router-link>

          <a class="navbar-item" id="logout" @click="signOut()"> Logout </a>
        </div>
      </div>
    </template>
  </b-navbar>
</template>


<script>
import firebase from "firebase/app";
import { mapGetters } from "vuex";
import NavbarItem from "./NavbarItem.vue";

export default {
  components: { NavbarItem },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          if (this.$route.params.school) {
            this.$router.push({
              name: "Home",
            });
            this.$router.go();
          } else {
            this.$router.push({
              name: "Welcome",
            });
          }
        });
    },
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      schoolMeta: "getSchoolMeta",
    }),
  },
};
</script>