<template>
  <div id="app">
    <router-view />

    <BottomNav v-if="$route.meta.mobile" />

    <b-loading
      :is-full-page="true"
      v-model="showLoading"
      :can-cancel="false"
    ></b-loading>

    <offline @detected-condition="handleConnectivityChange"> </offline>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar";
import BottomNav from "./components/BottomNav";
import Navbar from "./components/Navbar";
import { mapGetters } from "vuex";
import offline from "v-offline";

export default {
  name: "app",
  components: { Sidebar, BottomNav, Navbar, offline },
  created() {
    if (this.$route.meta.noData) {
      this.$store.state.loading = false;
    }
  },
  data() {
    return {
      isLoading: true,
      online: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      loading: "loading",
    }),
    showLoading() {
      if (!this.online || this.loading) return true;

      if (!this.$store.state.staticAssetsLoaded) {
        if (!this.$route.meta.noData && (this.user.loggedIn && this.user.data && !!this.user.data.role)) {
          return true;
        }

        return false;
      }

      return false;
    },
  },
  methods: {
    handleConnectivityChange(status) {
      this.online = status;
    },
    ensureRouteDefined() {
      const route = this.$route;

      return new Promise(function (resolve, reject) {
        (function waitForLoaded() {
          if (route.params.name) return resolve();
          setTimeout(waitForLoaded, 30);
        })();
      });
    },
  },
};
</script>

<style lang="scss">
@import "variables";

// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

@import "overrides";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.noSelect,
button,
a {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

// mark students in transit on the classroom view
.student-in-transit {
  border: 2px solid $grey-dark;
  border-radius: calc(0.4em + 2px);

  @extend .has-background-info-light;

  .card-header {
    @extend .has-background-info;
    .card-header-title {
      @extend .has-text-white;
    }
  }

  .card-footer {
    @extend .has-background-grey-dark;

    .card-footer-item {
      @extend .has-text-grey-lighter;
    }
  }
}

#html5-qrcode-button-camera-permission, #html5-qrcode-button-camera-start {
    @extend .button;
    @extend .is-primary;
    @extend .is-medium;
}

#html5-qrcode-anchor-scan-type-change {
  @extend .button;
  @extend .is-light;
}

#html5-qrcode-button-camera-stop {
  @extend .button;
  @extend .is-danger;
  @extend .is-light;
}

#reader__dashboard_section_csr {
  @extend .mb-3;
}

#homepage-stepper {
  .step-details {
    margin-right: 0.5rem;
  }
  .step-content {
    min-height: 100px;
  }
}

.has-fullheight {
  height: 100%
}

#homepage-hero {
  position: relative;
}

#homepage-hero:before {
  background-image: url(https://images.unsplash.com/photo-1509062522246-3755977927d7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHN0dWRlbnRzfGVufDB8fDB8fHww&auto=format&fit=crop&w=700&q=60);
  background-size: cover;
  filter: brightness(0.4) blur(2px);
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-position: 50% 35%; 
}

</style>

