
<template>
  <div>
    <ItemDisplay
      :icon="icon"
      :action="(item) => setBusState(item.name, actionState)"
      :hasAction="hasAction"
      :items="locations"
    />
  </div>
</template>

<script>
import ItemDisplay from "./ItemDisplay.vue";
import { mapGetters } from "vuex";

export default {
  components: { ItemDisplay },
  props: {
    icon: { type: String, default: "fas fa-shipping-fast" },
    filter: { type: Function, default: () => true },
    actionState: { type: String },
    hasAction: {type: Boolean, default: false}
  },
  methods: {
    setBusState(name, state) {
      this.$store.dispatch("updateBus", {
        busName: name,
        busState: state,
      });
    },
  },
  computed: {
    ...mapGetters({
      dismissalProviders: "getDismissalProviders",
    }),
    locations() {
      return this.dismissalProviders
        ? this.dismissalProviders.filter(
            ({ type, state }) => type !== "Car" && this.filter(state)
          )
        : [];
    },
  },
};
</script>

