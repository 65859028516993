var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modalDisplay)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_c('p',{staticClass:"title is-4"},[_c('b',[_vm._v(_vm._s(_vm.modalData.name))]),_vm._v(" "+_vm._s(_vm.modalData.id)+" ")]),_c('p',{staticClass:"subtitle is-6"},[_vm._v("Classroom: "+_vm._s(_vm.modalData.classroom))]),_c('article',{staticClass:"media",staticStyle:{"display":"flex","align-items":"center"}},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image"},[_c('span',{staticClass:"icon",class:{
                  'has-text-success': _vm.d.state == 'CLEARED',
                  'has-text-warning': _vm.d.state == 'ARRIVED',
                  'has-text-info': _vm.d.state == 'STUDENT_IN_TRANSIT',
                }},[_c('i',{class:_vm.d.type === 'Bus'
                      ? 'fas fa-bus'
                      : _vm.d.type === 'Daycare'
                      ? 'fas fa-shuttle-van'
                      : 'fas fa-car',attrs:{"aria-hidden":"true"}})])])]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[(_vm.d.type !== 'Car')?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.d.name))]),_c('span',{staticClass:"heading"},[_vm._v(_vm._s(_vm.d.state))])]):_c('p',[_c('b',[_vm._v("Station "+_vm._s(_vm.modalData.station))])])])])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }