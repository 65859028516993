import firebase from 'firebase/app'
import 'firebase/database'
import store from "./store";


const firebaseConfig = {
  apiKey: "AIzaSyA5wCIPLYUArL2FFBq4kG30QPPekq8gFig",
  authDomain: "dismissal-app.firebaseapp.com",
  databaseURL: "https://dismissal-app-default-rtdb.firebaseio.com",
  projectId: "dismissal-app",
  storageBucket: "dismissal-app.appspot.com",
  messagingSenderId: "164467737339",
  appId: "1:164467737339:web:c91ceab94287bfc264e65e"
};

export const db = firebase
  .initializeApp(firebaseConfig)
  .database()
