import Caller from '../views/Caller.vue'
import Staging from '../views/Staging.vue'
import Cleared from '../views/Cleared.vue'
import AllDisplay from "../views/AllDisplay.vue"
import Home from "../views/Home.vue"
import Classroom from "../views/Classroom.vue"
import Admin from "../views/Admin.vue"
import Landing from "../views/Landing.vue"
import NotFound from "../views/NotFound.vue"
import Schools from "../views/Schools.vue"

export const routes = [
    {
        path: '/s/:school/caller',
        name: 'Caller',
        component: Caller,
        meta: { mobile: true, requiresAuth: true }
    },
    {
        path: '/s/:school/all',
        name: 'Student/Bus Lookup',
        component: AllDisplay,
        meta: { mobile: true, requiresAuth: true }
    },
    {
        path: '/s/:school/staging',
        name: 'Staging',
        component: Staging,
        meta: { mobile: true, requiresAuth: true }
    },
    {
        path: '/s/:school/cleared',
        name: 'Cleared',
        component: Cleared,
        meta: { mobile: true, requiresAuth: true }
    },
    {
        path: '/s/:school/classroom',
        name: 'Classroom',
        component: Classroom,
        meta: { requiresAuth: true }
    },
    {
        path: '/s/:school/admin',
        name: 'Admin',
        component: Admin,
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/s/:school/',
        name: 'Home',
        component: Home,
        meta: {}
    },
    {
        path: '/s',
        name: 'Schools',
        component: Schools,
        meta: {}
    },
    {
        path: '/',
        name: 'Welcome',
        component: Landing,
        meta: { noData: true }
    }, {
        path: '/:catchAll(.*)',
        redirect: { name: 'NotFound' }
    },
    {
        path: '/404',
        component: NotFound,
        name: 'NotFound',
        meta: { noData: true }
    }

]
