<template>
  <router-link
    :to="to"
    v-slot="{ href, route, navigate, isActive, isExactActive }"
    custom
  >
    <b-navbar-item :href="href"> 
        <slot>{{route.name}} </slot>
    </b-navbar-item>
  </router-link>
</template>

<script>
export default {
    props: ['to']
}
</script>