<template>
  <div
    class="tabs is-toggle is-fullwidth bottom-padding-conditional"
    style="position: fixed; bottom: 0; width: 100%; background: white"
  >
    <ul>
      <router-link
        :to="{ name: link.name }"
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-for="link in items"
        :key="link.name"
        custom
      >
        <li
          :class="[
            isActive && 'is-active',
            isExactActive && 'router-link-exact-active',
          ]"
        >
          <a :href="href" @click="navigate">
            <span class="icon is-small">
              <i :class="link.icon" aria-hidden="true"></i>
            </span>
            <span>{{ link.name }}</span>
          </a>
        </li>
      </router-link>
    </ul>
  </div>
</template>


<script>
export default {
  data() {
    return {
      items: [
        { name: "Caller", icon: "fas fa-phone" },
        { name: "Staging", icon: "fas fa-list" },
        { name: "Cleared", icon: "fas fa-check" },
      ],
    };
  },
};
</script>

<style lang="scss">
.bottom-padding-conditional {
  // When a user is on a iPhone X device, we can use this user agent styling to ensure that the right amount of space is given to prevent phone UI form interfering with our element
  padding-bottom: env(safe-area-inset-bottom);
  margin-bottom: 0 !important;
}
</style>