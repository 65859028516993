<template>
  <div>
    <barcode-scanner     
        style="width: 100%;"
        :active="scannerVisible"
        @result="onScan"
        @closed="scannerVisible = false"
        v-if="schoolMeta.barcodeScanningEnabled"
      />
    <div class="columns is-d is-mobile cars is-multiline is-variable is-2 mb-5">
      <div
        v-for="spot in currentZoneMembers"
        :key="spot"
        class="column is-half-mobile is-one-third-tablet"
        :class="{ 'add-min-height': $route.name !== 'Caller' }"
      >
        <div
          class=""
          style="display: flex; flex-direction: column"
          v-if="$route.name === 'Caller'"
        >
          <div class="buttons has-addons is-flex" style="flex-wrap: nowrap;">
            <button
              :class="{ 'is-light': lastStation !== spot }"
              class="button is-large is-success is-flex-grow-1 is-flex"
              aria-label="choose spot"
              :disabled="!currentStudent"
              @click="setStation(spot)"
            >
              <span class="icon">
                <b-icon icon="plus" size="is-small"> </b-icon>
              </span>
              <span class="is-size-3"> {{ "" + spot }}</span>
            </button>
            <button class="button is-large is-primary is-light" @click="openScanner(spot)"
              v-if="schoolMeta.barcodeScanningEnabled">
              <span class="icon is-small">
                <i class="fa fa-camera"></i>
              </span>
            </button>
          </div>
        </div>

        <div class="card" style="display: flex; flex-direction: column" v-else>
          <header class="card-header">
            <p class="card-header-title">{{ "" + spot }}</p>
          </header>

          <div class="card-content p-0 station-cars">
            <b-tooltip
              type="is-dark"
              :triggers="['click']"
              :auto-close="['outside', 'escape']"
              v-for="student in students.filter(
                (student) =>
                  student.station == spot &&
                  ['ARRIVED', 'STUDENT_IN_TRANSIT'].includes(student.state)
              )"
              :key="student.id"
              size="is-medium"
            >
              <template v-slot:content>
                <div class="">
                  <button
                    @click="setStation(spot, student.id, null)"
                    class="button is-danger is-outlined m-2 is-small"
                  >
                    <b-icon icon="minus" size="is-large"></b-icon>
                  </button>

                  <button
                    @click="setStation(spot, student.id, 'CLEARED')"
                    class="button is-success is-outlined m-2 is-small"
                  >
                    <b-icon icon="check" size="is-large"></b-icon>
                  </button>

                  <button
                    @click="showModal(student.id)"
                    class="button is-info is-outlined m-2 is-small"
                  >
                    <b-icon icon="info" size="is-large"></b-icon>
                  </button>
                </div>
              </template>
              <div
                class="box m-1 p-0"
                :class="{
                  'has-background-info': student.state === 'STUDENT_IN_TRANSIT',
                  'has-background-danger': secondsSinceUpdated[student.id] > (schoolMeta.studentTimeout || 120),
                }"
              >
              <b-progress size="is-small" class="mb-0 time-progress" :value="(secondsSinceUpdated[student.id] / (schoolMeta.studentTimeout || 120)) * 100"></b-progress>
                <div class="p-4">
                  <p
                    class="title is-5 has-text-centered"
                    :class="{
                      'has-text-info-light':
                        student.state === 'STUDENT_IN_TRANSIT',
                      'has-text-danger-light':
                      secondsSinceUpdated[student.id] > (schoolMeta.studentTimeout || 120)
                    }"
                  >
                    {{ student.id }}
                  </p>

                  <p
                    v-if="$route.name == 'Staging'"
                    class="subtitle is-7 has-text-centered"
                    :class="{
                      'has-text-info-light':
                        student.state === 'STUDENT_IN_TRANSIT',
                      'has-text-danger-light':
                        secondsSinceUpdated[student.id] > (schoolMeta.studentTimeout || 120)
                    }"
                  >
                    {{ student.name.split(" ")[0] }}
                    <br />
                    {{ student.name.split(" ").slice(1).join(" ") }}
                  </p>
                </div>
                <!-- <p class="subtitle is-6">{{ student.name }}</p> -->
              </div>
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="modalDisplay" :width="300" scroll="keep">
      <modal :modalData="modalData" :modalDisplay="modalDisplay" />
    </b-modal>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import BarcodeScanner from "./BarcodeScanner.vue";
import Modal from "./Modal.vue";

export default {
  components: { Modal, BarcodeScanner },
  props: ["currentStudent"],
  computed: {
    ...mapGetters({
      students: "getStudents",
      teachers: "getTeachers",
      dismissalProviders: "getDismissalProviders",
      schoolMeta: "getSchoolMeta",
    }),
    currentZoneMembers(){
      if(this.$store.state.zone === -1) {
        // special case, return all zones
        return Array.from({length: this.schoolMeta.numStations}, (_, i) => i + 1)
      } else {
        return this.schoolMeta.zones[this.$store.state.zone]
      }
    },
    secondsSinceUpdated() {
      this.refreshKey;
      const studentsToSeconds = {}
      this.students.forEach(s => {
        if (s.updated) {
          studentsToSeconds[s.id] = this.secondsSinceDate(s.updated)
        }
      })
      return studentsToSeconds
    }
  },
  methods: {
    secondsSinceDate(dateString) {
      return (new Date() - new Date(dateString)) / 1000
    },
    setStation(loc, id, state = "ARRIVED") {
      console.log(loc, id);
      const studentID = id || this.currentStudent.id;
      const studentName = this.currentStudent?.name
      this.$emit("reset");

      if (this.$route.name === "Caller") {
        this.lastStation = loc;
      }

      this.$store
        .dispatch("updateStudent", {
          studentID: studentID,
          studentState: state,
          station: loc ? loc : "CLEARED",
        })
        .then((r) => {
          console.log("user updated!");
          // const { state, station } = r.data.data;

          this.$buefy.toast.open({
            duration: 3000,
            message: studentName ? `${studentName} (#${studentID}) updated` 
              : `Student #${studentID} updated`,
            position: "is-top",
            type: "is-success",
          });
        })
        .catch((e) => {
          console.log(e);
          let { code, message, response } = e;

          if (response) {
            const { message: specificMessage } = response.data.error;
            message += ": " + specificMessage;
          }

          this.$buefy.toast.open({
            duration: 5000,
            message: `Error updating student #${studentID}  - ${message}`,
            type: "is-danger",
          });
        });
    },
    showModal(studentID) {
      this.modalData = this.students.find((x) => x.id == studentID);
      this.modalDisplay = true;
    },
    openScanner(spot) {
      this.scanClickSpot = spot
      this.scannerVisible = true
    },
    onScan (studentID) {
      this.$emit("studentChanged", studentID)
      this.$nextTick(() => {
        if(this.currentStudent){
          this.setStation(this.scanClickSpot)
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Invalid ID",
            type: "is-danger",
          });
          this.$emit("studentChanged", null)
        }
        this.scannerVisible = false
        this.scanClickSpot = null
      })
    }
  },
  data() {
    return {
      modalDisplay: false,
      modalData: null,
      currentId: null,
      lastStation: null,
      scannerVisible: false,
      scanClickSpot: -1,
      refreshKey: 0
    };
  },
  mounted() {
    window.setInterval(() => this.refreshKey++, 1000)
  }
};
</script>

<style>
.station-cars {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
}

.time-progress .progress {
  border-radius: 8px 8px 0 0 !important;
  height: 0.4em;
}

div.has-background-info.has-background-danger {
  background: repeating-linear-gradient(
    45deg,
    #e74c3c,
    #e74c3c 30px,
    #3298dc 30px,
    #3298dc 60px
  ) !important;
}
</style>