<template>
  <div>
    <nav class="navbar is-transparent">
      <div class="">
        <div class="navbar-brand">
          <div class="navbar-item">
            <h1 class="title is-4 has-text-light">Lightbulb Dismiss</h1>
          </div>
        </div>
      </div>
    </nav>
    <section class="hero is-primary is-medium is-bold" id="homepage-hero">
      <div class="hero-body mt-6">
        <div class="container is-max-widescreen page-content">
          <h1 class="title is-size-1-mobile is-1 has-text-weight-bold">
            Make your dismissals fast and safe.
          </h1>
          <p class="is-size-4 mb-5">
            Get your students home from school quickly, easily, and safely with our turnkey, innovative dismissal
            platform. Save valuable time and eliminate headaches for you and your staff.
          </p>
          <div class="buttons">
            <a href="mailto:contact@lightbulb.education" target="_blank" class="button is-link is-medium">Request a
              Demo</a>
            <router-link :to="'/s'" class="button is-medium">Find Your School</router-link>

          </div>
        </div>
      </div>
    </section>
    <section class="hero">
      <div class="hero-body">
        <div class="container is-max-widescreen">

          <h1 class="title is-size-2-mobile is-2 has-text-weight-bold">
            How it works
          </h1>

          <b-steps :label-position="'left'" :size="'is-medium'" id="homepage-stepper" :animateInitially="true">
            <b-step-item label="Parent Check In" icon="fas fa-phone" :clickable="true">
              <p class="is-size-5 has-text-centered">
                A staff member checks in an arriving parent by
                the ID displayed on their car's dashboard.
              </p>
            </b-step-item>

            <b-step-item label="Student Dismissed" icon="fas fa-child" :clickable="true">

              <p class="is-size-5 has-text-centered">
                The student's teacher gets a notification on their screen
                that a parent has arrived to pick up a student in their class.
              </p>
            </b-step-item>

            <b-step-item label="Student Cleared" icon="fas fa-check" :clickable="true">

              <p class="is-size-5 has-text-centered">
                The student enters the car and is marked as dismissed once
                the car leaves.
              </p>
            </b-step-item>
          </b-steps>

          <div class="columns mt-4">
            <div class="column is-one-desktop">
              <h2 class="title is-size-4">Classrom Demo Walkthrough</h2>
              <iframe width="500" height="300" src="https://www.youtube.com/embed/E-IlABYzDkc" frameborder="0"
                class="w-100"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen ></iframe>
            </div>

            <div class="column is-one-desktop">
              <h2 class="title is-size-4">Regular Demo Walkthrough</h2>
              <iframe width="500" height="300" src="https://www.youtube.com/embed/DfHrkrTUKlI" frameborder="0"
                class="w-100"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container is-max-widescreen">
          <h1 class="title is-size-2-mobile is-2 has-text-weight-bold">
            Features
          </h1>

          <div class="columns is-multiline">
            <div class="column is-half is-one-quarter-desktop" v-for="feature in features">
              <div class="card has-fullheight">
                <div class="card-content">
                  <span class="is-flex is-justify-content-center">
                    <i :class="feature.icon + ' fa-4x'"></i>
                  </span>
                  <h3 class="is-size-4 mb-2 mt-4 has-text-weight-bold">{{ feature.title }}</h3>
                  <p class="is-size-5">{{ feature.content }}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </section>

    <!-- <section class="section">
      <div class="container is-max-widescreen">
        <h1 class="title is-size-2-mobile is-2">
          Testimonials
        </h1>
      </div>
    </section> -->

    <section class="hero">

      <div class="hero-body">

        <div class="container is-max-widescreen">
          <h1 class="title is-size-2-mobile is-2 has-text-weight-bold">
            Our team
          </h1>

          <div class="columns">
            <div class="column is-one-half" v-for="member in team">
              <div class="card has-fullheight">
                <div class="card-content">
                  <figure class="image is-96x96 mb-2" style="margin: 0 auto">
                    <img class="is-rounded" :src="member.pfp || 'https://bulma.io/images/placeholders/128x128.png'">
                  </figure>
                  <h3 class="is-size-4 mb-2 has-text-centered">{{ member.name }}</h3>
                  <p>{{ member.content }}</p>
                  <p class="buttons is-justify-content-center">
                    <a class="button is-rounded" :href="'mailto:' + member.email">
                      <span class="icon is-small">
                        <i class="fas fa-envelope"></i>
                      </span>
                    </a>
                    <a class="button is-rounded" :href="member.linkedin">
                      <span class="icon is-small">
                        <i class="fa-brands fa-linkedin"></i>
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container is-max-widescreen page-content">
          <h1 class="title is-size-2-mobile is-2 has-text-weight-bold">
            Get In Touch
          </h1>
          <p class="is-size-5 mb-5">
            Make your dismissal process quicker, safer, and less stressful. Contact us for a live demo.
          </p>
          <div class="buttons">
            <a href="mailto:contact@lightbulb.education" target="_blank" class="button is-link is-medium">Request a
              Demo</a>
          </div>
        </div>
      </div>
    </section>

    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          Created with 💙 by
          <strong><a href="https://lightbulb.education" target="_blank">Lightbulb Education</a></strong>. © 2023
        </p>
      </div>
    </footer>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      labelPosition: 'left',
      position: null,
      size: 'is-large',
      features: [
        {
          title: "Classroom View",
          icon: 'fas fa-chalkboard-user',
          content: "Teachers get an at a glance view of who is ready to be dismissed in their class, along with notification sounds when parents arrive."
        },
        {
          title: "Caller View",
          icon: 'fas fa-mobile',
          content: "Staff can efficiently mark arrived parents using a streamlined and battle tested user interface."
        },
        {
          title: "Busses and Daycares",
          icon: 'fas fa-bus',
          content: "Busses and Daycare shuttles that arrive can also be marked as present by Callers and display in every classroom."
        },
        {
          title: "Customizable Security",
          icon: 'fas fa-lock',
          content: "Restrict use of the system to specific users, or to those in your school district."
        },
      ],
      team: [
        { name: "Jonathan Chacko", email: "me@jchacko.dev", linkedin: "https://linkedin.com/in/jonchacko/", pfp: "/img/profiles/jonathan.jpeg" },
        { name: "Adam Nguyen", email: "adam@lightbulb.education", linkedin: "https://linkedin.com/in/nguyen-adam/", pfp: "/img/profiles/adam.jpg" }
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
    loggedIn() {
      return this.user.loggedIn
    }
  },
  mounted() {
    if (this.user.loggedIn) {
      window.location.pathname = "/s";
    }
  },
  watch: {
    loggedIn(val) {
      if (val) {
        window.location.pathname = "/s";
      }
    },
  },
};
</script>