<template>
  <section>
    <b-tabs expanded>
      <b-tab-item label="Students" icon="users">
        <b-table
          :data="students"
          :columns="studentColumns"
          :mobile-cards="false"
        >
        </b-table>
      </b-tab-item>

      <b-tab-item label="Busses" icon="bus">
        <b-table :data="dismissalProviders" :columns="busColumns" :mobile-cards="false">
        </b-table>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

 <script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      students: "getStudents",
      dismissalProviders: "getDismissalProviders",
    }),
  },
  data() {
    return {
      studentColumns: [
        {
          field: "id",
          label: "ID",
          width: "100",
          numeric: true,
          searchable: true,
        },
        {
          field: "name",
          label: "Name",
          searchable: true,
        },
        {
          field: "dismissal_location.type",
          label: "Type",
          searchable: true,
        },
        {
          field: "dismissal_location.name",
          label: "Dismissal Location",
          searchable: true,
        },
        {
          field: "station",
          label: "Station",
          searchable: true,
        },
        {
          field: "state",
          label: "Status",
        },
      ],
      busColumns: [
        {
          field: "name",
          label: "Name",
          searchable: true,
        },
        {
          field: "type",
          label: "Type",
          searchable: true,
        },
        {
          field: "state",
          label: "Status",
        },
      ],
    };
  },
};
</script>