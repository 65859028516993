
<template>
  <div>
    <ItemDisplay
      :icon="icon"
      :action="(item) => setCarState(item.id, actionState)"
      :hasAction="false"
      :items="modifiedStudents"
      :modal="true"
    />
  </div>
</template>

<script>
import ItemDisplay from "./ItemDisplay.vue";
import { mapGetters } from "vuex";

export default {
  components: { ItemDisplay },
  props: {
    icon: { type: String, default: "fas fa-shipping-fast" },
    filter: { type: Function, default: () => true },
    actionState: { type: String },
    hasAction: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      students: "getStudents",
      dismissalLocations: "getDismissalProviders",
    }),
    modifiedStudents() {
      const students = [...this.students];

      students.forEach((x, i) => {
        students[i].type = x.dismissal_location_.type;
        // console.log(x.dismissal_location.type)
        if (students[i].type !== "Car" && !x.state) {
            students[i].state = this.dismissalLocations.find(y => y.name == x.dismissal_location_.name).state
        }
      });

      return students;
    },
  },
};
</script>