<template>
  <div>
    <Navbar />

    <section
      class="hero is-vcentered is-bold is-fullheight-with-navbar is-dark"
      v-if="!$store.getters.user.loggedIn && $store.getters.getSchoolMeta"
    >
      <div class="hero-body">
        <div class="container has-text-centered">
          <p class="title is-2" v-if="$store.getters.getSchoolMeta">
            Welcome to the {{ $store.getters.getSchoolMeta.name }} Dismissal
            App!
          </p>
          <login />
        </div>
      </div>
    </section>

    <section
      class="section"
      v-else-if="$store.getters.user.loggedIn && $store.getters.user.data"
    >
      <div class="container">
        <h1 class="title is-1">
          Welcome, {{ $store.getters.user.data.displayName.split(" ")[0] }}.
        </h1>
        <div class="buttons">
        <router-link
          class="button is-primary is-large"
          :to="{ name: 'Classroom' }"
          >Classroom View</router-link
        >
        <router-link
          class="button is-dark is-large"
          :to="{ name: 'Caller' }"
          >Caller Screen</router-link
        >

        </div>

        <h2 class="title">Car Riders</h2>
        <bar-chart :chartData="chartData" />
      </div>
    </section>
  </div>
</template>

<script>
import Login from "./Login.vue";
import Navbar from "./Navbar";
import { mapGetters } from "vuex";
import BarChart from "./BarChart.vue";

export default {
  components: { Login, Navbar, BarChart },
  computed: {
    ...mapGetters({
      students: "getStudents",
      teachers: "getTeachers",
      dismissalProviders: "getDismissalProviders",
      user: "user",
      schoolMeta: "getSchoolMeta",
    }),
    filteredStudents() {
      return this.students.filter((x) => "state" in x && x.state) || [];
    },
    chartData() {
      return {
        type: "bar",
        data: {
          labels: ["Cars"],
          datasets: [
            {
              label: "Arrived",
              data: [
                this.filteredStudents.filter((x) => x.state == "ARRIVED")
                  .length,
              ],
              backgroundColor: "#fdeeed",
              borderColor: "#c32818",
              borderWidth: 1,
            },
            {
              label: "In Transit",
              data: [
                this.filteredStudents.filter(
                  (x) => x.state == "STUDENT_IN_TRANSIT"
                ).length,
              ],
              backgroundColor: "#f2f5f8",
              borderColor: "#6185a8",
              borderWidth: 1,
            },
            {
              label: "Cleared",
              data: [
                this.filteredStudents.filter((x) => x.state == "CLEARED")
                  .length,
              ],
              backgroundColor: "#eefbf4",
              borderColor: "#1d8147",
              borderWidth: 1,
            },
          ],
        },
        options: {
          indexAxis: "y",
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              beginAtZero: true,
              stacked: true,
            },
          },
        },
      };
    },
  },
  mounted() {
    document.body.classList.add("has-navbar-fixed-top");
  },
  destroyed() {
    document.body.classList.remove("has-navbar-fixed-top");
  },
};
</script>