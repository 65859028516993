<template>
    <div class="tabs is-small is-toggle mb-3">
        <ul class="is-justify-content-center">
            <!-- Default to All Zones -->
            <li :class="{ 'is-active': $store.state.zone == -1 }">
                <a @click="$store.state.zone = -1">
                    <span>All</span>
                </a>
            </li>
            <li :class="{ 'is-active': $store.state.zone == i }" v-for="(zoneName, i) in schoolMeta.zoneNames" :key="i">
                <a @click="$store.state.zone = i">
                    <span>{{ zoneName }}</span>
                </a>
            </li>
        </ul>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    // value represents an index in the school's zone settings
    // a value of -1 (default) means to show all zones
    computed: {
        ...mapGetters({
            schoolMeta: "getSchoolMeta",
        }),
    },
};
</script>