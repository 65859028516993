<template>
  <div>
    <Container>
      <b-tabs expanded>
        <b-tab-item label="Students" icon="users">
          <student-display :hasAction="false" />
        </b-tab-item>

        <b-tab-item label="Bus" icon="bus">
          <BusDisplay :hasAction="false" />
        </b-tab-item>
      </b-tabs>
    </Container>
  </div>
</template>


<script>
import CarDisplay from "../components/CarDisplay";
import BusDisplay from "../components/BusDisplay";
// import BusItem from "../components/BusItem";
import Container from "../components/Container";

import { mapGetters } from "vuex";
import StudentDisplay from "../components/StudentDisplay.vue";

export default {
  components: { CarDisplay, BusDisplay, StudentDisplay, Container },
};
</script>
