<template>
  <div>
    <div id="firebaseui-auth-container"></div>
  </div>
</template>

<script>
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import firebase from "firebase/app";

export default {
  props: {
    'signInSuccessUrl': {
      type: String,
    }
  },
  mounted() {
    // FirebaseUI config.
    const uiConfig = {
      signInSuccessUrl: this.signInSuccessUrl || "/s/" + this.$route.params.school,
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          // clientId:
          //   "164467737339-hoob9j96uq3r6can0rgs2ngphukfmu7m.apps.googleusercontent.com",
          // customParameters: {
          //   // Forces account selection even when one account
          //   // is available.
          //   hd: "humbleisd.net",
          // },
        },
      ],
      // credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
      //   tosUrl: "<your-tos-url>",
      //   privacyPolicyUrl: "",
      signInFlow: "popup"
    };

    // Initialize the FirebaseUI Widget using Firebase.
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());

    // The start method will wait until the DOM is loaded.
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>