<template>
  <div>
    <h1 class="title is-3">Data</h1>

    <div v-for="name in Object.keys(tableData)" :key="name" class="mt-4">
      <h2 class="title is-4 mb-3">{{ name }}</h2>

      <b-table
        :data="tableData[name]"
        :columns="schema[name]"
        focusable
        narrowed
        class="preview-table"
        :sticky-header="true"
        :mobile-cards="false"
        striped
        :loading="!tableData[name]"
      >
      </b-table>
    </div>

    <button
      class="button is-danger mt-5"
      @click="
        resetModalDisplay = true;
        funnyWord = funnyWords[Math.floor(Math.random() * funnyWords.length)];
      "
    >
      Reset Dismissal States
    </button>

    <b-modal v-model="resetModalDisplay" :width="400" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <div class="title is-4">
              Are you sure you want to reset the dismissal states?
            </div>
            <div class="subtitle">
              Confirm by typing <b>{{ funnyWord.toLowerCase() }}</b> below.
            </div>

            <b-field>
              <b-input type="text" v-model="verificationText"> </b-input>
            </b-field>

            <button
              class="button is-danger mt-2"
              @click="$store.dispatch('resetDismissalStates')"
              :disabled="verificationText !== funnyWord.toLowerCase()"
            >
              Yes do it!
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      schema: {
        "Dismissal Providers": [
          { field: "name", label: "Name", searchable: true },
          { field: "type", label: "Type", searchable: true },
          { field: "state", label: "State (Live)", searchable: true },
        ],
        Students: [
          { field: "id", label: "ID", numeric: true, searchable: true },
          { field: "name", label: "Name", width: 250, searchable: true },
          {
            field: "classroom",
            label: "Classroom",
            width: 250,
            searchable: true,
          },
          {
            field: "dismissal_location",
            label: "Dismissal Location",
            width: 100,
            searchable: true,
          },
          { field: "station", label: "Station (Live)", searchable: true },
          { field: "state", label: "State (Live)", searchable: true },
        ],
        Teachers: [
          { field: "name", label: "Name", searchable: true },
          { field: "email", label: "Email", searchable: true },
        ],
      },
      resetModalDisplay: false,
      funnyWords: [
        "Bumfuzzle",
        "Fartlek",
        "Everywhen",
        "Erf",
        "Hullaballoo",
        "Meldrop",
        "Obelus",
        "Sozzled",
        "Bumbershoot",
        "Titter",
        "Smicker",
        "Cleek",
        "Whippersnapper",
        "Bibliokept",
        "Salopettes",
        "Lollygag",
        "Bamboozled",
        "Flabbergast",
        "Cattywampus",
        "Finifugal",
        "Widdershins",
        "Gobbledygook",
        "Popple",
        "Gardyloo",
      ],
      funnyWord: "Bumfuzzle",
      verificationText: "",
    };
  },
  computed: {
    ...mapGetters({
      students: "getStudents",
      teachers: "getTeachers",
      dismissalProviders: "getDismissalProviders",
      user: "user",
      users: "users",
      schoolMeta: "getSchoolMeta",
    }),
    tableData() {
      return {
        "Dismissal Providers": this.dismissalProviders,
        Students: this.students,
        Teachers: this.teachers,
      };
    },
  },
};
</script>