<template>
  <section class="section" :class="{'padding-0-mobile': $route.meta.mobile}">
    <navbar />
    <div class="container" :class="{ 'is-max-desktop': $route.meta.mobile }">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import Navbar from "./Navbar.vue";

export default {
  components: { Navbar },
  mounted() {
    document.body.classList.add("has-navbar-fixed-top");
  },
  destroyed() {
    document.body.classList.remove("has-navbar-fixed-top");
  },
};
</script>

<style>
@media screen and (max-width: 768px) {
  .padding-0-mobile {
    padding: 0;
  }
}
</style>