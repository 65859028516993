<template>
  <div>
    <div v-if="loggedIn">
      <Overview />
    </div>
    <div v-else>
      <Welcome />
    </div>
  </div>
</template>

<script>
import Overview from "../components/Overview";
import Welcome from "../components/Welcome";

export default {
  components: { Overview, Welcome },
  computed: {
    loggedIn() {
      return this.$store.state.user.loggedin;
    },
  },
};
</script>
