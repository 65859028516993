<template>
  <b-steps
    v-model="activeStep"
    :animated="true"
    :rounded="true"
    :has-navigation="false"
    mobile-mode="compact"
  >
    <b-step-item step="1" label="Create Sheet" :clickable="true" class="box">
      <h1 class="title">Create Your Sheet</h1>
      <p>
        Copy the
        <a
          href="https://docs.google.com/spreadsheets/d/18e8-Bn8adJy3XNPDAfsPJ7UIIG51AYDOBpU8gaI5C-Q/copy"
          target="_blank"
          >starter Google Sheet</a
        >
        and input your data.
      </p>

      <b-notification
        type="is-warning"
        has-icon
        :closable="false"
        class="mt-4 sheet-warning"
      >
        Make sure not to edit the existing column or sheet names.
      </b-notification>

      <b-button
        type="is-primary"
        icon-pack="fas"
        icon-right="chevron-right"
        @click="activeStep++"
      >
        Next
      </b-button>
    </b-step-item>

    <b-step-item
      step="2"
      label="Connect Sheet"
      :type="{
        'is-success': steps[1].data,
        'is-danger': steps[1].error,
      }"
      class="box"
      :clickable="true"
    >
      <h1 class="title">Connect Your Sheet</h1>
      <p>Share your sheet with the following email address and paste the link to it below.</p>
      <article class="message is-dark mt-4 mb-4">
        <div class="message-body">
          gsheets-loader@dismissal-app.iam.gserviceaccount.com
        </div>
      </article>

      <b-field :type="validSheet ? 'is-success' : ''" class="mb-4">
        <b-input
          placeholder="Link to your Google Sheet..."
          icon-pack="fas"
          icon="link"
          size="is-medium"
          required
          validation-message="Please insert a valid Google Sheets Link"
          v-model="sheetURL"
          pattern="https:\/\/docs\.google\.com\/spreadsheets\/d\/.*\/edit.*"
        >
        </b-input>
      </b-field>

      <div class="buttons">
        <b-button
          type="is-primary"
          icon-pack="fas"
          icon-left="chevron-left"
          @click="activeStep--"
          outlined
        >
        </b-button>
        <b-button
          type="is-primary"
          icon-pack="fas"
          icon-right="chevron-right"
          @click="cloudFn()"
          :disabled="!validSheet"
          :loading="steps[1].loading"
        >
          Get Data
        </b-button>
      </div>
    </b-step-item>

    <b-step-item
      step="3"
      label="Confirmation"
      :type="{ 'is-success': steps[2].data, 'is-danger': steps[2].error }"
      class="box"
      :clickable="steps[1].data ? true : false"
    >
      <h1 class="title">Confirmation</h1>
      <p>
        Preview the data below.
        <b>Importing will overwrite existing app data irrevocably.</b>
      </p>

      <div v-if="steps[1].data">
        <div
          v-for="name in Object.keys(steps[1].data.data)"
          :key="name"
          class="mt-4"
        >
          <h2 class="title is-4 mb-3">{{ name }}</h2>

          <b-table
            :data="steps[1].data.data[name]"
            :columns="schema[name]"
            focusable
            narrowed
            class="preview-table"
            :sticky-header="true"
            :mobile-cards="false"
          >
          </b-table>
        </div>

        <div class="mt-4">
          <h2 class="title is-4 mb-3">Warnings</h2>
          <pre
            class="has-background-dark has-text-white"
            v-html="steps[1].data.warnings.replaceAll('\\n', `<br>`)"
          ></pre>
        </div>
      </div>

      <!-- <b-field>
              <b-input
                placeholder="Google Sheets Link..."
                icon-pack="fas"
                icon="link"
                size="is-medium"
              >
              </b-input>
            </b-field> -->

      <div class="buttons mt-4">
        <b-button
          type="is-primary"
          icon-pack="fas"
          icon-left="chevron-left"
          @click="activeStep--"
          outlined
        >
        </b-button>
        <b-button
          type="is-danger"
          icon-pack="fas"
          icon-right="chevron-right"
          @click="cloudFn((step = 2))"
          :loading="steps[2].loading"
        >
          Import Data
        </b-button>
      </div>
    </b-step-item>

    <b-step-item
      step="4"
      label="Finish"
      class="box"
      :clickable="steps[2].data ? true : false"
      :type="{ 'is-success': steps[2].data, 'is-danger': steps[2].error }"
    >
      <h1 class="title">Import Complete</h1>
      <p v-if="steps[2].data">
        {{ steps[2].data.message }} Make sure to keep your sheet secure. We'll
        remember the link for next time.
      </p>

      <div class="buttons mt-4">
        <b-button
          type="is-primary"
          icon-pack="fas"
          icon-left="chevron-left"
          @click="activeStep--"
          outlined
        >
        </b-button>
        <!-- <b-button
              icon-pack="fas"
              type="is-primary"
              icon-right="chevron-right"
            >
              Continue
            </b-button> -->
      </div>
    </b-step-item>
  </b-steps>
</template>

<script>
import Container from "../components/Container.vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  components: { Container },
  data() {
    return {
      activeStep: 0,
      sheetURL: this.defaultSheetURL,
      steps: [
        { loading: false, error: false },
        { loading: false, error: false, data: null },
        { loading: false, error: false, data: null },
      ],
      schema: {
        "Dismissal Providers": [
          { field: "name", label: "Name" },
          { field: "type", label: "Type" },
        ],
        Students: [
          { field: "id", label: "ID", numeric: true },
          { field: "name", label: "Name" },
          { field: "classroom", label: "Classroom" },
          { field: "dismissal_location", label: "Dismissal Location" },
        ],
        Teachers: [
          { field: "name", label: "Name" },
          { field: "email", label: "Email" },
        ],
      },
    };
  },
  methods: {
    cloudFn(step = 1) {
      const school = this.$route.params.school;

      if (!this.validSheet) return;

      this.steps[step].loading = true;
      this.steps[step].error = false;

      const { token } = this.$store.state.user.data;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const BASE_URL = this.$store.getters.cloudFnURL;

      const fn =
        step == 1 ? "preview_sheet" : step == 2 ? "upload_sheet" : null;

      axios
        .get(
          `${BASE_URL}/${fn}?sheet_url=${encodeURIComponent(
            this.sheetURL
          )}&school=${school}`,
          config
        )
        .then((d) => {
          console.log(d);
          const resp = d.data;
          this.steps[step].data = resp;

          if (step == 2) {
            this.$store.dispatch("setDefaultSheet", {
              sheetURL: this.sheetURL,
            });
          }

          this.steps[step].loading = false;
          this.activeStep++;
        })
        .catch((error) => {
          console.log(error);
          this.steps[step].error = true;
          this.steps[step].loading = false;

          console.log(error.response);

          this.$buefy.toast.open({
            duration: 5000,
            message: error.response
              ? error.response.data.error.message
              : "Unknown Error",
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
  },
  computed: {
    validSheet() {
      const regex = /https:\/\/docs\.google\.com\/spreadsheets\/d\/.*\/edit/g;
      return regex.test(this.sheetURL);
    },
    ...mapGetters({
      defaultSheetURL: "getSheetURL",
    }),
  },
  watch: {
    defaultSheetURL(val) {
      if (val) this.activeStep = 1
      this.sheetURL = val;
    },
  },
  mounted() {
    this.sheetURL = this.defaultSheetURL;
    if (this.sheetURL) this.activeStep = 1
  },
};
</script>

<style>
.step-content {
  flex-direction: row;
  max-width: 50rem;
  margin: 0 auto;
}
.step-details {
  margin-top: 0.5rem;
}
.preview-table table {
  max-height: 20rem;
  border-radius: 0.4em;
}
.notices.has-custom-container {
  position: fixed !important;
}
.sheet-warning .media{
  display: flex;
  align-items: center;
}
</style>