<template>
  <container>
    <section>
      <div class="columns">
        <div class="column is-narrow" style="min-width: 250px">
          <b-menu class="box">
            <b-menu-list label="Menu">
              <!-- <b-menu-item
                icon="tachometer-alt"
                label="Dashboard"
                @click="tab = 'dashboard'"
                :active="tab == 'dashboard'"
              ></b-menu-item> -->
              <b-menu-item
                icon="table"
                label="Data"
                :active="tab == 'data'"
                @click="tab = 'data'"
              ></b-menu-item>
              <b-menu-item
                icon="upload"
                label="Import"
                :active="tab == 'import'"
                @click="tab = 'import'"
              ></b-menu-item>
              <b-menu-item
                icon="users"
                label="Users"
                :active="tab == 'users'"
                @click="tab = 'users'"
              ></b-menu-item>

              <b-menu-item
                icon="cog"
                label="Settings"
                :active="tab == 'settings'"
                @click="tab = 'settings'"
              ></b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
        <div class="column">
          <!-- <div v-show="tab == 'dashboard'"></div> -->
          <div v-show="tab == 'data'">
            <admin-data />
          </div>
          <div v-show="tab == 'import'">
            <keep-alive><sheet-stepper /></keep-alive>
          </div>
          <div v-show="tab == 'users'">
            <keep-alive><admin-users /></keep-alive>
          </div>
          <div v-show="tab == 'settings'">
            <admin-settings />
          </div>
        </div>
      </div>
    </section>
  </container>
</template>

<script>
import Container from "../components/Container.vue";
import SheetStepper from "../components/SheetStepper.vue";
import AdminSettings from "../components/AdminSettings.vue";
import AdminUsers from "../components/AdminUsers.vue";

import axios from "axios";
import AdminData from "../components/AdminData.vue";

export default {
  components: { Container, SheetStepper, AdminSettings, AdminUsers, AdminData },
  data() {
    return {
      tab: "data",
    };
  },
};
</script>