var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.schoolMeta.barcodeScanningEnabled)?_c('barcode-scanner',{staticStyle:{"width":"100%"},attrs:{"active":_vm.scannerVisible},on:{"result":_vm.onScan,"closed":function($event){_vm.scannerVisible = false}}}):_vm._e(),_c('div',{staticClass:"columns is-d is-mobile cars is-multiline is-variable is-2 mb-5"},_vm._l((_vm.currentZoneMembers),function(spot){return _c('div',{key:spot,staticClass:"column is-half-mobile is-one-third-tablet",class:{ 'add-min-height': _vm.$route.name !== 'Caller' }},[(_vm.$route.name === 'Caller')?_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"buttons has-addons is-flex",staticStyle:{"flex-wrap":"nowrap"}},[_c('button',{staticClass:"button is-large is-success is-flex-grow-1 is-flex",class:{ 'is-light': _vm.lastStation !== spot },attrs:{"aria-label":"choose spot","disabled":!_vm.currentStudent},on:{"click":function($event){return _vm.setStation(spot)}}},[_c('span',{staticClass:"icon"},[_c('b-icon',{attrs:{"icon":"plus","size":"is-small"}})],1),_c('span',{staticClass:"is-size-3"},[_vm._v(" "+_vm._s("" + spot))])]),(_vm.schoolMeta.barcodeScanningEnabled)?_c('button',{staticClass:"button is-large is-primary is-light",on:{"click":function($event){return _vm.openScanner(spot)}}},[_vm._m(0,true)]):_vm._e()])]):_c('div',{staticClass:"card",staticStyle:{"display":"flex","flex-direction":"column"}},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v(_vm._s("" + spot))])]),_c('div',{staticClass:"card-content p-0 station-cars"},_vm._l((_vm.students.filter(
              (student) =>
                student.station == spot &&
                ['ARRIVED', 'STUDENT_IN_TRANSIT'].includes(student.state)
            )),function(student){return _c('b-tooltip',{key:student.id,attrs:{"type":"is-dark","triggers":['click'],"auto-close":['outside', 'escape'],"size":"is-medium"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{},[_c('button',{staticClass:"button is-danger is-outlined m-2 is-small",on:{"click":function($event){return _vm.setStation(spot, student.id, null)}}},[_c('b-icon',{attrs:{"icon":"minus","size":"is-large"}})],1),_c('button',{staticClass:"button is-success is-outlined m-2 is-small",on:{"click":function($event){return _vm.setStation(spot, student.id, 'CLEARED')}}},[_c('b-icon',{attrs:{"icon":"check","size":"is-large"}})],1),_c('button',{staticClass:"button is-info is-outlined m-2 is-small",on:{"click":function($event){return _vm.showModal(student.id)}}},[_c('b-icon',{attrs:{"icon":"info","size":"is-large"}})],1)])]},proxy:true}],null,true)},[_c('div',{staticClass:"box m-1 p-0",class:{
                'has-background-info': student.state === 'STUDENT_IN_TRANSIT',
                'has-background-danger': _vm.secondsSinceUpdated[student.id] > (_vm.schoolMeta.studentTimeout || 120),
              }},[_c('b-progress',{staticClass:"mb-0 time-progress",attrs:{"size":"is-small","value":(_vm.secondsSinceUpdated[student.id] / (_vm.schoolMeta.studentTimeout || 120)) * 100}}),_c('div',{staticClass:"p-4"},[_c('p',{staticClass:"title is-5 has-text-centered",class:{
                    'has-text-info-light':
                      student.state === 'STUDENT_IN_TRANSIT',
                    'has-text-danger-light':
                    _vm.secondsSinceUpdated[student.id] > (_vm.schoolMeta.studentTimeout || 120)
                  }},[_vm._v(" "+_vm._s(student.id)+" ")]),(_vm.$route.name == 'Staging')?_c('p',{staticClass:"subtitle is-7 has-text-centered",class:{
                    'has-text-info-light':
                      student.state === 'STUDENT_IN_TRANSIT',
                    'has-text-danger-light':
                      _vm.secondsSinceUpdated[student.id] > (_vm.schoolMeta.studentTimeout || 120)
                  }},[_vm._v(" "+_vm._s(student.name.split(" ")[0])+" "),_c('br'),_vm._v(" "+_vm._s(student.name.split(" ").slice(1).join(" "))+" ")]):_vm._e()])],1)])}),1)])])}),0),_c('b-modal',{attrs:{"width":300,"scroll":"keep"},model:{value:(_vm.modalDisplay),callback:function ($$v) {_vm.modalDisplay=$$v},expression:"modalDisplay"}},[_c('modal',{attrs:{"modalData":_vm.modalData,"modalDisplay":_vm.modalDisplay}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fa fa-camera"})])
}]

export { render, staticRenderFns }