<template>
  <div>
    <Container>
      <b-tabs v-model="activeTab" expanded>
        <b-tab-item label="Car" icon="car">
          <ZoneToggle/>
          <CarCaller />
        </b-tab-item>

        <b-tab-item label="Bus" icon="bus">
          <BusDisplay
            icon="fas fa-map-pin"
            :filter="(state) => !['ARRIVED', 'CLEARED'].includes(state)"
            actionState="ARRIVED"
            :hasAction="true"
          />
        </b-tab-item>
      </b-tabs>
    </Container>
  </div>
</template>


<script>
import CarCaller from "../components/CarCaller";
import BusDisplay from "../components/BusDisplay";
import Container from "../components/Container";
import ZoneToggle from "../components/ZoneToggle.vue";

export default {
  components: { CarCaller, BusDisplay, Container, ZoneToggle },
  data() {
    return {
      activeTab: this.$store.state.transitModeTab,
    };
  },
  watch: {
    activeTab(val) {
      this.$store.commit("setTabState", val);
    },
  },
};
</script>
