import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";
import { routes } from './routes'
import { db } from '../db'
import { ensureLoaded, generateManifest, initFirebaseAuth } from './utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {

  const { school } = to.params

  if (school) {

    // check the list of valid schools
    db.ref('meta/schools').get().then(x => {

      // is the school param exists on a page meant for data
      if (!to.params.noData) {
        // and its not a valid school
        if (!Object.keys(x.val()).includes(school)) {
          // throw a 404
          next({ name: 'NotFound', path: to.path })
          store.state.loading = false;
          return;
        }

        // if its a valid school, load its data
        else {
          generateManifest(school, x.val()[school])
          initFirebaseAuth()

          // connect to rtdb if it hasn't been done before
          if (!store.state.live) {
            store.dispatch("bindLive", { "school": school }).then(() => {
              store.state.loading = false;
            });
          }
        }
      }
    })

  } else {

    initFirebaseAuth()

    store.state.loading = false;

  }


  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    ensureLoaded().then(() => {

      if (!store.getters.user.loggedIn || (to.matched.some(record => record.meta.requiresAdmin) && store.getters.user.data.role !== 'admin')) {
        if (to.params.school) {
          next({ name: 'Home', params: to.params })
        } else {
          next({ name: 'Schools', params: to.params })
        }
      } else {
        next() // go to wherever I'm going
      }
    })

  } else {
    next() // does not require auth, make sure to always call next()!
  }
})


export default router
