
<template>
  <div>
    <ItemDisplay
      :icon="icon"
      :action="(item) => setCarState(item.id, actionState)"
      :hasAction="hasAction"
      :items="clearedStudents"
      :modal="true"
    />
  </div>
</template>

<script>
import ItemDisplay from "./ItemDisplay.vue";
import { mapGetters } from "vuex";

export default {
  components: { ItemDisplay },
  props: {
    icon: { type: String, default: "fas fa-shipping-fast" },
    filter: { type: Function, default: () => true },
    actionState: { type: String },
    hasAction: { type: Boolean, default: false },
  },
  methods: {
    setCarState(studentID, state) {
      this.$store.dispatch("updateStudentFast", {
        studentID: studentID,
        studentState: state,
      });

      this.$emit("set");

    },
  },
  computed: {
    ...mapGetters({
      students: "getStudents",
    }),
    clearedStudents() {
      return this.students
        ? this.students.filter(({ state }) => this.filter(state))
        : [];
    },
  },
};
</script>

