<template>
  <div>
    <h1 class="title is-3">Classroom View</h1>

    <div class="block">
      <b-checkbox v-model="settings.classroom" native-value="bus">
        Busses/Daycares
      </b-checkbox>
      <b-checkbox v-model="settings.classroom" native-value="car">
        Car Riders
      </b-checkbox>
    </div>

    <h1 class="title is-3">Caller/Staging View</h1>
    <div class="block">
      <b-checkbox v-model="settings.barcodeScanningEnabled" native-value="barcodeScanning">
        Barcode Scanning
      </b-checkbox>
    </div>

    <b-field label="Student Timeout (seconds)">
      <b-input type="number"
        v-model="settings.studentTimeout"></b-input>
    </b-field>

    
    <h1 class="title is-4">Modify Dismissal Zones</h1>
    <b-field label="Number of Dismissal Stations">
      <b-numberinput v-model="settings.numStations" :min="1" :max="16" controls-position="compact"></b-numberinput>
    </b-field>
    <div class="columns is-multiline">
      <div class="column is-full" v-for="(zoneElements, i) in settings.zones" :key="i">
        <div class="panel">
          <p class="panel-heading is-flex is-align-items-center is-justify-content-space-between">
            <span style="width: 100%;" contenteditable="true" @input="event => oneZoneNameInput(event, i)">
              {{ settings.zoneNames[i] }}
            </span>
            <button class="delete is-small" @click="removeZone(i)" v-show="settings.zones.length > 1"></button>
          </p>
          <div class="panel-block p-3">
            <draggable class="columns list-group is-multiline is-mobile" :list="zoneElements" @start="drag = true"
              @end="drag = false" :group="'zones'" style="min-height: 75px; width: 100%;">
              <div v-for="element in zoneElements" class="list-group-item box m-2" :key="element" style="cursor: grab;">{{
                element }}
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <button class="button is-block mb-3" style="margin: 0 auto;" :disabled="settings.zones.length >= maxZones"
      @click="addZone()">
      <span class="icon is-medium">
        <i class="fas fa-plus"></i>
      </span>
      <span>Add Zone</span>
    </button>

    <h1 class="title is-3">Access Management</h1>

    <h1 class="title is-4">Users</h1>

    <b-field label="User Access">
      <b-select placeholder="Select a subject" v-model="settings.access">
        <option value="EMAIL_WHITELIST">Email Whitelist</option>
        <option value="IN_DOMAIN">In Domain</option>
        <option value="IN_DOMAIN_OR_EMAIL_WHITELIST">In Domain + Email Whitelist</option>
        <option value="PUBLIC">Public</option>
      </b-select>
    </b-field>

    <b-field label="Domain">
      <b-input type="text" :disabled="!['IN_DOMAIN', 'IN_DOMAIN_OR_EMAIL_WHITELIST'].includes(settings.access)"
        v-model="settings.domain"></b-input>
    </b-field>

    <b-field label="Enter list of user emails">
      <b-taginput v-model="settings.users" :data="filteredUsers" autocomplete ref="user_taginput" icon="chevron-right"
        placeholder="Add an email" @typing="getFilteredUsers" :allow-new="true" :open-on-focus="true"
        @focus="getFilteredUsers('')"
        :disabled="!['EMAIL_WHITELIST', 'IN_DOMAIN_OR_EMAIL_WHITELIST'].includes(settings.access)">
        <template slot-scope="props">
          <strong v-if="users.find((x) => x.email === props.option)">{{
            users.find((x) => x.email === props.option).name
          }}</strong>
          {{ props.option }}
        </template>
        <template #empty> No suggestions </template>
        <template #selected="props">
          <b-tag v-for="(tag, index) in props.tags" :key="index" :type="'is-dark'" :tabstop="false" closable
            @close="$refs.user_taginput.removeTag(index, $event)">
            {{ tag.name || tag }}
          </b-tag>
        </template>
      </b-taginput>
    </b-field>

    <h1 class="title is-4 mt-5">Admins</h1>
    <b-field label="Enter list of administrator emails">
      <b-taginput v-model="settings.admins" :data="filteredUsers" autocomplete ref="admin_taginput" icon="chevron-right"
        placeholder="Add an email" @typing="getFilteredUsers" :allow-new="true" :open-on-focus="true"
        @focus="getFilteredUsers('')">
        <template slot-scope="props">
          <strong v-if="users.find((x) => x.email === props.option)">{{
            users.find((x) => x.email === props.option).name
          }}</strong>
          {{ props.option }}
        </template>
        <template #empty> No suggestions </template>
        <template #selected="props">
          <b-tag v-for="(tag, index) in props.tags" :key="index" :type="'is-dark'" :tabstop="false" closable
            @close="$refs.admin_taginput.removeTag(index, $event)">
            {{ tag.name || tag }}
          </b-tag>
        </template>
      </b-taginput>
    </b-field>

    <button class="button is-primary mt-5" @click="$store.dispatch('updateSettings', { settings })">
      Save
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from 'vuedraggable'

export default {
  data() {
    return {
      // users: [],
      filteredUsers: [],
      tags: [],
      settings: {
        classroom: [], admins: [], users: [], domains: null,
        zones: [[1, 2, 3, 4, 5, 6, 7, 8]], zoneNames: ["Zone 1"], numStations: 8,
        barcodeScanningEnabled: false,
        studentTimeout: 120
      },
      drag: false,
      maxZones: 5,
    };
  },
  components: {
    draggable
  },
  computed: {
    ...mapGetters({
      students: "getStudents",
      teachers: "getTeachers",
      dismissalProviders: "getDismissalProviders",
      user: "user",
      users: "users",
      schoolMeta: "getSchoolMeta",
    }),
    numStations() {
      return this.settings.numStations
    }
  },
  mounted() {
    this.defaultSettings();
  },
  watch: {
    schoolMeta() {
      this.defaultSettings();
    },
    numStations(newStationCount, oldStationCount) {
      if (newStationCount > oldStationCount
        // make sure it doesn't exist already in another zone before adding it
        && !this.settings.zones.some(zone => zone.includes(newStationCount))) {
        // increase in numStations, add location to zones
        this.settings.zones[0].push(newStationCount)
      } else if (newStationCount < oldStationCount) {
        // decrease in numStations, remove location from zones
        for (let i = 0; i < this.settings.zones.length; i++) {
          for (let j = 0; j < this.settings.zones[i].length; j++) {
            if (this.settings.zones[i][j] > newStationCount) {
              // splicing out individual elements is necessary
              // replacing the whole array with a filtered array causes reference issues
              this.settings.zones[i].splice(j, 1)
            }
          }
        }
      }
    }
  },
  methods: {
    defaultSettings() {
      this.settings = { ...this.settings, ...this.schoolMeta };
    },
    getFilteredUsers(text) {
      this.filteredUsers = this.users
        .filter((option) => {
          return (
            option.name.toString().toLowerCase().includes(text) &&
            !this.settings.admins.includes(option.email) &&
            (this.settings.users
              ? !this.settings.users.includes(option.email)
              : true)
          );
        })
        .map((x) => x.email);
    },
    addZone() {
      if (this.settings.zones.length < this.maxZones) {
        this.settings.zones = [...this.settings.zones, []] // add an empty zone
        this.settings.zoneNames.push(`Zone ${this.settings.zones.length}`)
      }
    },
    removeZone(index) {
      const numZones = this.settings.zones.length
      if (numZones > 1) {
        if (index == 0) {
          // special case when removing the first zone, add items to second zone
          this.settings.zones[1] = this.settings.zones[1].concat(this.settings.zones[0])
        } else {
          // move the items from the zone that's being deleted to the previous zone
          this.settings.zones[index - 1] = this.settings.zones[index - 1].concat(this.settings.zones[index])
        }
        this.settings.zones.splice(index, 1)
        this.settings.zoneNames.splice(index, 1)
      }
    },
    oneZoneNameInput(event, index) {
      const value = event.target.innerText;
      this.settings.zoneNames[index] = value;
    },
  },
};
</script>

<style scoped>
.top-corner {
  right: 1rem;
  position: absolute;
  top: 1rem;
}
</style>