<template>
    <div class="modal" :class="{ 'is-active': active }">
        <div class="modal-background" @click="$emit('closed')"></div>
        <div class="modal-content">
            <div class="box p-0" style="overflow: hidden">
                <div id="reader" style="border 0;"></div>
            </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="$emit('closed')"></button>
    </div>
</template>

<script>
import { Html5QrcodeScanner, Html5QrcodeScannerState } from "html5-qrcode";
const config = { 
    fps: 20, 
    // qrbox: { width: 500, height: 200 } ,
}

export default {
    props: ["active"],
    data() {
        return {
            html5QrCode: null,
        }
    },
    mounted() {
        this.html5QrCode = new Html5QrcodeScanner("reader", config)
        this.html5QrCode.render(this.onScanSuccess)

    },
    methods: {
        onScanSuccess(decodedText, decodedResult) {
            // should be integers
            if (decodedResult.result.format.formatName == "CODE_39"
                && !isNaN(decodedText)) {
                this.$emit('result', parseInt(decodedText));
            }
        }
    },
    watch: {
        active(isActive) {
            if (isActive) {
                if (this.html5QrCode.getState() === Html5QrcodeScannerState.PAUSED) {
                    this.html5QrCode.resume()
                }
            } else {
                if (this.html5QrCode.getState() === Html5QrcodeScannerState.SCANNING) {
                    this.html5QrCode.pause(true)
                }
                // this.html5QrCode.clear()
            }
        }
    }
}

</script>