<template>
  <div>
    <container>
      <div v-if="$store.getters.user.loggedIn">
        <h1 class="title is-2">Find Your School</h1>

        <div class="columns is-multiline">
          <div
            class="column is-one-third"
            v-for="school in filteredSchools"
            :key="school.id"
          >
            <div class="card height-100">
              <div class="card-content height-100">
                <div
                  class="content height-100"
                  style="display: flex; flex-direction: column"
                >
                  <h3 class="title mb-2">{{ school.name }}</h3>
                  <p style="flex: 1">
                    <span
                      class="tag is-info is-small"
                      v-if="school.role === 'admin'"
                      >Admin</span
                    >
                  </p>

                  <a class="button is-primary" :href="`/s/${school.id}`"
                    >Open</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
          <h1 class="title is-2 has-text-centered">Login</h1>
        <login :signInSuccessUrl="'/s'"/>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "../components/Container.vue";
import Login from "../components/Login.vue";
import { db } from "../db";

export default {
  components: { Container, Login },
  data() {
    return {
      schools: {},
    };
  },
  mounted() {
    //   console.log(this.$store.getters.user.data.claims.roles)
    db.ref("meta/schools")
      .get()
      .then((schools) => {
        this.schools = schools.val();
      });
  },
  computed: {
    roles() {
      return this.$store.getters.user.data
        ? this.$store.getters.user.data.claims.roles
        : [];
    },
    filteredSchools() {
      const { roles } = this;
      const schools = [];

      Object.keys(roles).forEach((school) => {
        schools.push({
          role: roles[school],
          name: this.schools[school],
          id: school,
        });
      });

      return schools;
    },
  },
};
</script>

<style lang="scss">
.height-100 {
  height: 100%;
}
</style>