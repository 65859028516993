<template>
  <article class="panel">
    <div class="panel-block">
      <p class="control has-icons-left">
        <input class="input is-info" type="text" placeholder="Search" v-model="search" @input="$event.target.composing = false"/>
        <span class="icon is-left">
          <i class="fas fa-search" aria-hidden="true"></i>
        </span>
      </p>
    </div>

    <div
      class="panel-block list-item"
      v-for="(item, i) in filteredItems"
      :key="i"
      @click="showModal(item)"
    >
      <span
        class="panel-icon"
        :class="{
          'has-text-success': item.state == 'CLEARED',
          'has-text-warning': item.state == 'ARRIVED',
          'has-text-info': item.state == 'STUDENT_IN_TRANSIT',
        }"
      >
        <i
          :class="
            item.type === 'Bus'
              ? 'fas fa-bus'
              : item.type === 'Daycare'
              ? 'fas fa-shuttle-van'
              : 'fas fa-car'
          "
          aria-hidden="true"
        ></i>
      </span>

      <span class="tag is-light mr-2" v-if="item.id"> {{ item.id }} </span>

      <span style="flex-grow: 1">{{ item.name }}</span>

      <div v-if="hasAction" class="buttons">
        <span v-if="i == currentSelected" class="confirm-buttons">
          <button
            class="button is-small is-light"
            @click.stop="currentSelected = null"
          >
            <span class="icon is-left has-text-danger">
              <i class="fas fa-times" aria-hidden="true"></i>
            </span>
          </button>
          <button class="button is-small is-light" @click.stop="action(item);currentSelected = null">
            <span class="icon is-left has-text-success">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
          </button>
        </span>

        <button
          class="button is-small is-light"
          @click.stop="currentSelected = i"
          v-else
        >
          <span class="icon is-left">
            <i :class="icon" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>

    <b-modal v-model="modalDisplay" :width="300" scroll="keep">
      <modal :modalData="modalData" :modalDisplay="modalDisplay"/>
    </b-modal>

  </article>
</template>

<script>
import * as fuzzysort from 'fuzzysort';
import Modal from './Modal.vue'

export default {
  components: {Modal},
  props: {
    icon: { type: String, default: "fas fa-shipping-fast" },
    action: { type: Function, default: (obj) => null },
    hasAction: { type: Boolean, default: false },
    items: { type: Array },
    modal: Boolean

  },
  data() {
    return {
      currentSelected: null,
      search: "",
      modalData: null,
      modalDisplay: false
    };
  },
  computed: {
    filteredItems(){
      const {search, items} = this

      const tempItems = JSON.parse(JSON.stringify(items))
      tempItems.forEach(({name, id}, i) => tempItems[i].searchKey = name + " " + id)

      return search ? fuzzysort.go(search, tempItems, {key: "searchKey"}).map(x => x.obj) : items
      // return []

    }
  },
  methods: {
    showModal(student) {
      if(this.modal == true){
        this.modalData = student;
        this.modalDisplay = true;
      }
    },
  }
  // methods: {
  //   setItemState(name, state) {
  //     this.$store.dispatch("updateBus", {
  //       busName: name,
  //       busState: state,
  //     });

  //     // this.currentId = 69420;
  //     this.currentSelected = null;
  //   },
  // },
};
</script>


<style>
.list-item {
  display: flex;
}
.confirm-buttons {
  flex-wrap: nowrap;
  display: flex;
}
</style>