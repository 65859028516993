import store from "../store";
import firebase from 'firebase/app'
import 'firebase/auth'
import { db } from '../db'

export function ensureLoaded() {
    return new Promise(function (resolve, reject) {
        (function waitForLoaded() {
            if (!store.state.loading) return resolve();
            setTimeout(waitForLoaded, 30);
        })();
    });
}

export function generateManifest(school, fullName) {
    const loc = window.location.origin

    const manifest = {
        "name": fullName + " - LB Dismiss",
        "short_name": fullName + " - LB Dismiss",
        "theme_color": "#34495e",
        "icons": [
            {
                "src": loc + "/img/icons/android-chrome-192x192.png",
                "sizes": "192x192",
                "type": "image/png"
            },
            {
                "src": loc + "/img/icons/android-chrome-512x512.png",
                "sizes": "512x512",
                "type": "image/png"
            },
            {
                "src": loc + "/img/icons/android-chrome-maskable-192x192.png",
                "sizes": "192x192",
                "type": "image/png",
                "purpose": "maskable"
            },
            {
                "src": loc + "/img/icons/android-chrome-maskable-512x512.png",
                "sizes": "512x512",
                "type": "image/png",
                "purpose": "maskable"
            }
        ],
        "start_url": loc + "/s/" + school,
        "display": "standalone",
        "background_color": "#34495e",
        "scope": loc
    }

    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);

    const manifestEle = document.querySelector('#my-manifest-placeholder')
    if (manifestEle) manifestEle.setAttribute('href', manifestURL);

}

export function initFirebaseAuth() {
    let callback = null;
    let metadataRef = null;
    // load firebase authentiation
    if (!store.state.authMounted) {
        firebase.auth().onAuthStateChanged(user => {

            store.state.authMounted = true

            // Remove previous listener.
            if (callback) {
                metadataRef.off('value', callback);
            }

            if (user) {
                console.log("Setting up permission update watcher")
                callback = (snapshot) => {
                    console.log("Refreshing ID Token...")
                    user.getIdToken(true).then(() => store.dispatch("fetchUser", user)).catch(x => console.log(e))
                }
                metadataRef = db.ref(`users_meta/${user.uid}/refresh`)
                metadataRef.get().then(x => console.log(x.val()))
                metadataRef.on('value', callback);
            }

            store.dispatch("fetchUser", user);
        });
    }
}