<template>
  <div class="card" v-if="modalDisplay">
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4">
            <b>{{ modalData.name }}</b> {{ modalData.id }}
          </p>
          <p class="subtitle is-6">Classroom: {{ modalData.classroom }}</p>

          <article class="media" style="display: flex; align-items: center">
            <figure class="media-left">
              <p class="image">
                <span
                  class="icon"
                  :class="{
                    'has-text-success': d.state == 'CLEARED',
                    'has-text-warning': d.state == 'ARRIVED',
                    'has-text-info': d.state == 'STUDENT_IN_TRANSIT',
                  }"
                >
                  <i
                    :class="
                      d.type === 'Bus'
                        ? 'fas fa-bus'
                        : d.type === 'Daycare'
                        ? 'fas fa-shuttle-van'
                        : 'fas fa-car'
                    "
                    aria-hidden="true"
                  ></i>
                </span>
              </p>
            </figure>
            <div class="media-content">
              <div class="content">
                <p v-if="d.type !== 'Car'">
                  <strong>{{ d.name }}</strong>
                  <span class="heading">{{ d.state }}</span>
                </p>
                <p v-else>
                  <b>Station {{ modalData.station }}</b>
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modalData", "modalDisplay"],
  data() {
    return {};
  },
  computed: {
    d: function () {
      return this.modalData.dismissal_location_;
    },
  },
};
</script>
