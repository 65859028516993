<template>
  <div>
    <Container>
      <b-tabs v-model="activeTab" expanded>
        <b-tab-item label="Car" icon="car">
          <CarDisplay
            icon="fas fa-undo"
            :filter="(state) => ['CLEARED'].includes(state)"
            actionState="ARRIVED"
            :hasAction="true"
          />
        </b-tab-item>

        <b-tab-item label="Bus" icon="bus">
          <BusDisplay
            icon="fas fa-undo"
            :filter="(state) => ['CLEARED'].includes(state)"
            actionState="ARRIVED"
            :hasAction="true"
          />
        </b-tab-item>
      </b-tabs>
    </Container>
  </div>
</template>


<script>
import BusDisplay from "../components/BusDisplay";
import CarDisplay from "../components/CarDisplay";
import Container from "../components/Container";

import { mapGetters } from "vuex";

export default {
  components: { BusDisplay, CarDisplay, Container },
  data() {
    return {
      activeTab: this.$store.state.transitModeTab,
    };
  },
  watch: {
    activeTab(val) {
      this.$store.commit("setTabState", val);
    },
  },
};
</script>
