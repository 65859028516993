<template>
  <div>

    <!-- Displays name whenever a valid ID is typed in -->
    <transition name="slide-fade" mode="out-in">
      <p class="student-current" :key="currentStudent.id">
        <b>{{ currentStudent ? currentStudent.name : "Enter a student ID" }}</b>
      </p>
    </transition>
    <b-field class="mt-2 mb-5">
      <input
        class="input is-large has-text-centered"
        type="number"
        placeholder="Student ID"
        v-model="currentId"
        autocomplete="off"
        pattern="[0-9]*"
      />
    </b-field>

    <CarStations :currentStudent="currentStudent" 
      @reset="currentId = 69420;currentId = null;"
      @studentChanged="(id) => currentId = id"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import CarStations from "@/components/CarStations.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CarStations,
  },
  computed: {
    ...mapGetters({
      students: "getStudents",
    }),
    currentStudent() {
      return this.students.find((x) => x.id == +this.currentId) || "";
    },
  },
  data() {
    return {
      currentId: null,
      carSpots: [{ i: 1 }, { i: 2 }, { i: 3 }, { i: 4 }, { i: 5 }, { i: 6 }],
    };
  },
};
</script>


<style>
.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  /* transform: translateX(10px); */
  opacity: 0;
}

.student-current {
  min-height: 2.4rem;
  text-align: center;
}

.card-header-icon {
  padding: 0.25rem 0.5rem !important;
}

.cars > .column > .card {
  height: 100%;
}
.cars > .add-min-height {
  min-height: 10rem;
}

.modal .modal-content {
  width: calc(100% - 32px) !important;
}
</style>