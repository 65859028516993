<template>
  <div>
    <h1 class="title is-3">Users</h1>
    <b-table :data="users" :columns="columns" class="mb-2"></b-table>
    <small> 👑<i> denotes an admin </i></small>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      columns: [
        {
          field: "_name_and_role",
          label: "Name",
        },
        {
          field: "email",
          label: "Email",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      schoolMeta: "getSchoolMeta",
      users: 'users'
    }),
  },
};
</script>
