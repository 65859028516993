import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firebaseAction } from 'vuexfire'
import { db } from '../db'
import { ToastProgrammatic as Toast } from 'buefy'
import firebase from 'firebase/app'
import 'firebase/storage'
import axios from "axios";

import router from '../router/index.js'

Vue.use(Vuex)

const permissionsToast = () => {
  Toast.open({
    type: 'is-danger',
    message: "You do not have the permissions to complete the action."
  })
}

let indefiniteToast

const school = window.location.pathname.split("/")[2]

export default new Vuex.Store({
  state: {
    dismissalProviders: [],
    teachers: [],
    students: [],
    live: null,
    transitModeTab: 0,
    zone: -1,
    user: {
      loggedIn: false,
      data: null
    },
    loading: true,
    authMounted: false,
    users: [],
    staticAssetsLoaded: false
  },
  getters: {
    cloudFnURL: () => {
      return process.env.NODE_ENV == "production"
        ? "https://us-central1-dismissal-app.cloudfunctions.net"
        : "http://localhost:5000";
    },
    loading: (state) => state.loading,
    users: (state) => state.users,
    user: (state) => state.user,
    getSchoolMeta: (state) => state.live?.meta,
    getSheetURL: (state) => state.live?.meta?.sheet,
    getDismissalProviders: (state) => {
      const { dismissalProviders, live } = state

      // add .state property based on rtdb live bindings
      dismissalProviders.forEach((bus, i) => {
        const currentLive = live.bus ? live.bus[encodeURIComponent(bus.name)] : null

        if (currentLive) {
          dismissalProviders[i].state = currentLive.state
        }

      })

      return dismissalProviders
    },

    // returns teachers with their students
    getTeachers: (state) => {
      const { teachers, students } = state

      // add .students property with list of students for each teacher
      teachers.forEach((teacher, i) => {
        teachers[i].students = students.filter(x => x.classroom == teacher.name)
      })

      teachers.sort((a, b) => (a.name > b.name) ? 1 : -1)

      return teachers
    },

    // returns students and syncs student data from firebase
    getStudents: (state) => {
      const { students, live } = state

      students.forEach((student, i) => {
        const currentLive = live.student ? live.student[student.id.toString()] : null

        if (currentLive) {
          students[i].station = currentLive.station
          students[i].state = currentLive.state
          if (currentLive.updated) {
            students[i].updated = currentLive.updated
          }
        }

        // add dismissal providoer reference
        const dismissalLoc = state.dismissalProviders.find(({ name }) => name == students[i].dismissal_location)

        if (dismissalLoc) {
          const { name, type } = dismissalLoc
          students[i].dismissal_location_ = { name, type }
        } else {
          students[i].dismissal_location_ = { "type": "Car" }
        }

      })

      return students
    }
  },
  mutations: {
    setDismissalProviders: (state, providers) => state.dismissalProviders = providers,
    setTeachers: (state, teachers) => state.teachers = teachers,
    setStudents: (state, students) => state.students = students,
    setTabState: (state, tabState) => state.transitModeTab = tabState,
    setUsers: (state, users) => state.users = users,
    ...vuexfireMutations,
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    }
  },
  actions: {
    bindLive: firebaseAction(({ bindFirebaseRef }, { school }) => {
      // return the promise returned by `bindFirebaseRef`
      return bindFirebaseRef('live', db.ref("schools/" + school))
    }),
    updateStudent({ commit, getters, state, dispatch }, { studentID, studentState, station }){

      if (getters.user.data.role !== 'admin' && getters.user.data.role !== 'user') {
        permissionsToast()
        return
      }

      const current = state.live.student[studentID]

      if (!station){
        if(current && current['station']){
          station = current['station']
        } else{
          Toast.open("Station missing")
          return;
        }
      }

      // make the request
      const BASE_URL = getters.cloudFnURL;

      const { token } = state.user.data;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 5 * 1000
      };
      

      return axios
        .get(
          `${BASE_URL}/update_student?studentId=${studentID}&state=${studentState}&station=${station}&school=${school}&updated=${Date()}`,
          config
        )

    },
    updateStudentFast: firebaseAction(({ state, getters }, { studentID, studentState, station }) => {

      if (getters.user.data.role !== 'admin' && getters.user.data.role !== 'user') {
        permissionsToast()
        return
      }

      // const student = { ...state.students.find(x => x.id == studentID) }
      const current = state.live.student[studentID]
      
      if (!station){
        if(current && current['station']){
          station = current['station']
        } else{
          Toast.open("Station missing")
          return;
        }
      }

      const live = {
        "state": studentState,
        "station": station,
        "updated": Date()
      }

      // return the promise so we can await this action
      return db
        .ref(`schools/${school}/student/${studentID}`)
        .set(live)
        .then(() => {
          console.log('user updated!')
        }).catch(e => {
          console.log(e)
          const { code, message } = e;
          Toast.open(message)

        })
    }),

    updateBus: firebaseAction(({ state, getters }, { busName, busState }) => {

      if (getters.user.data.role !== 'admin' && getters.user.data.role !== 'user') {
        permissionsToast()
        return
      }

      const live = {
        "state": busState,
      }

      // return the promise so we can await this action
      return db
        .ref(`schools/${school}/bus/${encodeURIComponent(busName)}`)
        .set(live)
        .then(() => {
          console.log('user updated!')
        }).catch(e => {
          console.log(e)
        })
    }),
    fetchUser({ commit, getters, state, dispatch }, user) {
      commit("SET_LOGGED_IN", user !== null);

      // if user is logged in and the current route requires data
      if (user && !router.currentRoute.meta.noData) {
        const { displayName, email, photoURL } = user

        user.getIdTokenResult().then((result) => {
          const { token, claims } = result
          const role = claims.roles[school] // get the role of the current user

          if (indefiniteToast) indefiniteToast.close()

          if (role !== 'admin' && role !== 'user') {

            if (school) {
              indefiniteToast = Toast.open({
                indefinite: true,
                message: `You do not have permissions to make changes.`,
                type: 'is-warning',
                position: 'is-bottom',
              })
            }
          } else {
            dispatch('fetchStaticAssets')

            if (role == 'admin' && token) dispatch('getUsers', { token })
          }

          commit("SET_USER", { displayName, email, photoURL, token, claims, role });
        });

      } else {
        commit("SET_USER", null);
      }
    },
    fetchStaticAssets({ commit, state }) {
      // load static data
      const storage = firebase.storage();
      const files = { 'students.json': 'setStudents', 'dismissal_providers.json': 'setDismissalProviders', 'teachers.json': 'setTeachers' }
      const requests = Object.keys(files).map(filename => storage.ref(`${school}/${filename}`).getDownloadURL().then(url => axios.get(url)))

      axios.all(requests).then(r => {
        Object.keys(files).forEach((filename, i) => {
          commit(files[filename], r[i].data)
        })
      }).then(() => {
        state.staticAssetsLoaded = true
      }).catch((e) => {
        Toast.open({message: `Data not loaded: ${e}`, type: 'is-danger'})
        state.staticAssetsLoaded = true
      })
    },
    getUsers({ state, commit, getters }, { token }) {

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const BASE_URL = getters.cloudFnURL

      axios
        .get(`${BASE_URL}/list_users?school=${school}`, config)
        .then((d) => {
          const resp = d.data;

          resp.forEach((x, i) => {
            if (x.role === 'admin') {
              resp[i]._name_and_role = x.name + ' 👑'
            } else {
              resp[i]._name_and_role = x.name
            }
          })

          commit('setUsers', resp)

        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);

          Toast.open({
            duration: 5000,
            message: error.response?.data?.error?.message || "Unknown Error Retrieving Users List",
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    updateSettings: firebaseAction(({ state, getters }, { settings }) => {

      if (getters.user.data.role !== 'admin') {
        permissionsToast()
        return
      }

      const updates = {}

      Object.keys(settings).forEach(key => {
        updates[`schools/${school}/meta/${key}`] = settings[key]
      })

      // return the promise so we can await this action
      return db
        .ref()
        .update(updates)
        .then(() => {
          console.log('settings updated!')
          Toast.open({ message: 'Settings Updated!', type: 'is-success' })
        }).catch(e => {
          console.log(e)
          const { code, message } = e;
          Toast.open(message)
        })
    }),
    setDefaultSheet: firebaseAction(({ state, getters }, { sheetURL }) => {

      if (getters.user.data.role !== 'admin') {
        permissionsToast()
        return
      }

      // return the promise so we can await this action
      return db
        .ref(`schools/${school}/meta/sheet`)
        .set(sheetURL)
        .then(() => {
          console.log('Sheet Updated!')
          // Toast.open({message: 'Settings Updated!', type: 'is-success'})
        }).catch(e => {
          console.log(e)
          const { code, message } = e;
          Toast.open(message)
        })
    }),
    resetDismissalStates: firebaseAction(({ state, getters }) => {

      if (getters.user.data.role !== 'admin') {
        permissionsToast()
        return
      }

      const updates = {}

      updates[`schools/${school}/bus`] = { 'PLACEHOLDER': 'PLACEHOLDER' }
      updates[`schools/${school}/student`] = { 'PLACEHOLDER': 'PLACEHOLDER' }

      // return the promise so we can await this action
      return db
        .ref()
        .update(updates)
        .then(() => {
          Toast.open({ message: 'Dismissal State Reset', type: 'is-success' })
        }).catch(e => {
          console.log(e)
          const { code, message } = e;
          Toast.open(message)
        })
    }),

  },
  modules: {
  }
})
