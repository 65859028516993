<template>
  <div
    class="chart-container"
    style="position: relative; height: 20vh; width: 100%"
  >
    <canvas ref="myChart"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
  props: ["chartData"],
  mounted() {
    // this.renderChart(this.chartdata, this.options);

    this.chart = new Chart(this.$refs["myChart"], this.chartData);
  },
  watch: {
    chartData(data) {
      console.log(data);
      console.log(this.chart.data.datasets);

      this.chart.data.datasets.forEach((dataset, i) => {
        dataset.data = [...data.data.datasets[i].data];
      });

      this.chart.update();
    },
  },
};
</script>
